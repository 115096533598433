<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th>Client</th>
            <th>Adresse</th>
            <th class="text-nowrap">Ville</th>
            <th class="text-nowrap">Code postal</th>
            <th class="text-nowrap">Pays</th>
            <th class="text-right">Retrait</th>
            <th class="text-right">Chronofresh</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="address in addresses" :key="address[0]">
            <td>
              <router-link :to="`/users/${addresses_meta[address[0]][0]}`">
                {{ address[3] }}
                <b>{{ address[2] }}</b>
              </router-link>
            </td>
            <td>
              <span v-if="address[5]">
                {{ address[5] }}
                <br />
              </span>
              <span v-if="address[4]">
                {{ address[4] }}
              </span>
              <br />
              {{ address[6] }}
            </td>
            <td>
              {{ address[8] }}
            </td>
            <td>
              {{ address[7] }}
            </td>
            <td>
              {{ address[9] }}
            </td>
            <td class="text-right">
              <button
                v-if="addresses_meta[address[0]][3] == false"
                class="btn btn-warning btn-sm"
                @click.prevent="
                  workshopWithdrawal(addresses_meta[address[0]][1], true)
                "
              >
                Non
              </button>
              <button
                v-else
                class="btn btn-primary btn-sm"
                @click.prevent="
                  workshopWithdrawal(addresses_meta[address[0]][1], false)
                "
              >
                Oui
              </button>
            </td>
            <td class="text-right">
              <button
                v-if="addresses_meta[address[0]][2] == false"
                class="btn btn-primary btn-sm"
                @click.prevent="
                  excludeFromChronofresh(addresses_meta[address[0]][1], true)
                "
              >
                Oui
              </button>
              <button
                v-else
                class="btn btn-warning btn-sm"
                @click.prevent="
                  excludeFromChronofresh(addresses_meta[address[0]][1], false)
                "
              >
                Non
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
    <!-- <pre>{{ addresses }}</pre> -->
    <!-- <pre>{{ addresses_meta }}</pre> -->
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

export default {
  name: 'ProductionSelections',
  components: { BOverlay },
  inheritAttrs: false,
  props: {
    productionBatch: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      addresses: [],
      addresses_meta: {},
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const resp = await this.$http.get(
        `/productions/batches/${this.productionBatch.id}/chronofresh/`,
        {}
      )
      this.addresses = resp.data.data
      this.addresses_meta = resp.data.meta
      this.loading = false
    },
    async excludeFromChronofresh(addressId, state) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.put(
        `/users/addresses/${addressId}/`,
        { exclude_from_delivery: state },
        {
          headers: {
            'X-Fields': 'id,exclude_from_delivery',
            'X-Keys': 'exclude_from_delivery',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.loading = false
      this.init()
    },
    async workshopWithdrawal(addressId, state) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.put(
        `/users/addresses/${addressId}/`,
        { workshop_withdrawal: state },
        {
          headers: {
            'X-Fields': 'id,workshop_withdrawal',
            'X-Keys': 'workshop_withdrawal',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.loading = false
      this.init()
    },
  },
}
</script>
