<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <b-form-group v-if="productionBatchId" label-for="input-label">
          <validation-provider
            #default="{ errors }"
            name="Famille"
            rules="required"
          >
            <b-form-select v-model="form.status" placeholder="Statut" required>
              <b-form-select-option
                v-for="option in ['PENDING', 'DELIVERED']"
                :key="option"
                :value="option"
              >
                {{ $t(`PRODUCTION.${option}`) }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="input-due_at">
          <validation-provider
            #default="{ errors }"
            name="Fabrication pour le"
            rules="required"
          >
            <b-form-datepicker
              v-model="form.due_at"
              placeholder="Livraison le"
              locale="fr"
              start-weekday="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <hr />
        <div class="row mt-2">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              <span v-if="creation == true"> Créer </span>
              <span v-else> Valider </span>
            </b-button>
            <b-button
              v-if="creation == false"
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="productionBatchId"
              v-b-modal.modal-delete
              variant="danger"
            >
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-item"
      centered
      hide-header-close
      title="Suprimmer la tâche planifiée"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteItem()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ query }} </pre> -->
    <!-- <pre>{{ form }} </pre> -->
    <!-- <pre>{{ dueAt }} </pre> -->
    <!-- <pre>{{ addQuantity }} </pre> -->
    <!-- <pre>{{ week }} </pre> -->
    <!-- <pre>{{ recipes }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BModal,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'CopyValue',
  components: {
    BForm,
    BFormGroup,

    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    productionBatchId: {
      default: null,
      type: String,
    },
    tasks: {
      type: Array,
      default() {
        return []
      },
    },
    dueAt: {
      type: String,
      default: null,
    },
    addQuantity: {
      type: Number,
      default: 0,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      form: {
        due_at: null,
        status: null,
      },
      constants: {},
    }
  },
  watch: {
    productionBatchId() {
      this.init()
    },
    dueAt(newValue) {
      this.form.due_at = newValue
    },
  },
  created() {
    this.init()
    if (this.dueAt) {
      this.form.due_at = this.dueAt
    }
  },
  methods: {
    async init() {
      if (this.productionBatchId) {
        const resp = await this.$http.get(
          `/productions/batches/${this.productionBatchId}/`,
          {
            headers: {
              'X-Fields': `${Object.keys(this.form).join(',')}`,
            },
          }
        )
        this.form = resp.data
      }
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.productionBatchId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const payload = { status: 'ENABLED', ...this.form, tasks: this.tasks }
        if (this.dueAt) {
          payload.due_at = this.dueAt
        }
        if (this.addQuantity >= 0) {
          payload.add_quantity = this.addQuantity
        }
        const resp = await this.$http.post('/productions/batches/', payload, {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        })
        this.$emit('created', resp.data)
        this.$emit('update:display', false)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/productions/batches/${this.productionBatchId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async deleteItem() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/productions/batches/${this.productionBatchId}/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('deleted', resp.data)
      this.$refs['delete-item'].hide()
    },
  },
}
</script>
