<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="productionBatch.id">
        <div class="row mb-1">
          <div class="col-lg-4">
            <h3>
              Planifié pour le
              {{ $dayjs(productionBatch.due_at).format('DD.MM.YYYY') }}
            </h3>
          </div>
          <div class="col-lg-8 text-right px-0">
            <a
              :href="productionBatch.recipes_summary_pdf"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Récapitulatif
            </a>
            <a
              :href="productionBatch.technical_sheets_pdf"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Fiches techniques
            </a>
            <a
              :href="productionBatch.ingredients_pdf"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Liste des ingrédients
            </a>
          </div>
        </div>
        <b-tabs content-class="mt-2">
          <b-tab title="Propriétés" active>
            <div class="row">
              <div class="col-lg-11">
                <dl v-if="displayBatchForm == false" class="row">
                  <dt class="col-sm-3 text-right">#</dt>
                  <dd class="col-sm-9">
                    <b><copy-value :value="productionBatch.id" /></b>
                  </dd>
                  <dt class="col-sm-3 text-right">Statut</dt>
                  <dd class="col-sm-9">
                    <b>{{ $t(`PRODUCTION.${productionBatch.status}`) }}</b>
                  </dd>
                  <dt class="col-sm-3 text-right">Livraison</dt>
                  <dd class="col-sm-9">
                    <b>{{ $dayjs(productionBatch.due_at).fromNow() }}</b>
                  </dd>
                  <dt class="col-sm-3 text-right">quantité</dt>
                  <dd class="col-sm-9">
                    <b>{{ productionBatch.quantities }}</b>
                  </dd>
                </dl>
                <production-batch-form
                  v-else
                  :production-batch-id="productionBatch.id"
                  :display.sync="displayBatchForm"
                  @updated="init"
                  @deleted="onDelete"
                />
              </div>
              <div class="col-lg-1">
                <button
                  class="btn btn-primary btn-sm"
                  @click.prevent="displayBatchForm = !displayBatchForm"
                >
                  <feather-icon icon="EditIcon" />
                </button>
              </div>
            </div>
          </b-tab>
          <b-tab title="Métadonnées">
            <pre>{{ productionBatch }}</pre>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="productionBatch.id">
        <div v-if="gotSiteTasks === true" class="row">
          <div class="col-lg-12 text-right">
            <a
              :href="`${productionBatch.selections_summary_pdf}?workshop_withdrawal=true`"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Retrait atelier
            </a>
            <a
              :href="`${productionBatch.selections_summary_pdf}?country_code=FR&workshop_withdrawal=false`"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Récapitulatif (FR)
            </a>
            <a
              :href="`${productionBatch.selections_summary_pdf}?country_code=LU&workshop_withdrawal=false`"
              target="_blank"
              class="btn btn-info btn-sm mr-1"
            >
              <font-awesome-icon icon="file-pdf" />
              Récapitulatif (LU)
            </a>
            <button
              class="btn btn-info btn-sm mr-1"
              @click.prevent="downloadChronofresh()"
            >
              <font-awesome-icon icon="file-csv" />
              Chronofrech
            </button>
          </div>
        </div>

        <b-tabs content-class="mt-2">
          <b-tab :title="`Taches (${productionBatch.tasks.length})`">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Fabrication</th>
                  <th class="text-center">Statut</th>
                  <th class="text-center">Qt.</th>
                  <th class="text-center">Qt. +</th>
                  <th class="text-right">Livraison</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="task in productionBatch.tasks">
                  <tr :key="task.id">
                    <td>
                      <span class="badge badge-light text-info">
                        {{ $t(`PRODUCTION.${task.source}`) }}
                      </span>
                    </td>
                    <td>
                      <h4>
                        <router-link :to="`/productions/tasks/${task.id}`">
                          {{ task.recipe.label }}
                        </router-link>
                      </h4>
                    </td>
                    <td class="text-center">
                      <span class="text-nowrap">
                        {{ $t(`PRODUCTION.${task.status}`) }}
                      </span>
                    </td>
                    <td class="text-center">{{ task.quantity }}</td>
                    <td class="text-center">Dont + {{ task.add_quantity }}</td>
                    <td class="text-right">
                      {{ $dayjs(task.due_at).format('DD.MM.YYYY') }}
                    </td>
                    <td class="text-right">
                      <button
                        class="btn btn-warning btn-sm"
                        @click.prevent="unlink(task.id)"
                      >
                        <font-awesome-icon icon="unlink" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-tab>
          <b-tab :title="`Ingédients (${productionBatch.ingredients.length})`">
            <recipe-ingredients-card
              :quantity="0"
              :ingredients="productionBatch.ingredients"
              :display="[
                'label',
                'suplier',
                'product_code',
                'quantity',
                'quantity_for_quantity',
                'cost_for_quantity',
              ]"
            />
          </b-tab>
          <b-tab v-if="gotSiteTasks === true" title="Livraison">
            <chronofresh-addresses :production-batch="productionBatch" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <!-- <pre>{{ productionBatch.ingredients }}</pre> -->
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BOverlay } from 'bootstrap-vue'
import RecipeIngredientsCard from '@/components/RecipeIngredientsCard.vue'
import ProductionBatchForm from '@/components/ProductionBatchForm.vue'
import ChronofreshAddresses from '@/components/ChronofreshAddresses.vue'

const productionFields =
  '*,tasks{id,status,source,production_batch_id,quantity,add_quantity,due_at,recipe{label}}'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BOverlay,
    RecipeIngredientsCard,
    ProductionBatchForm,
    ChronofreshAddresses,
  },
  data() {
    return {
      loading: false,
      displayBatchForm: false,
      productionBatch: { tasks: [] },
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 8,
        term: null,
      },
    }
  },
  computed: {
    gotSiteTasks() {
      let result = false
      if (this.productionBatch && this.productionBatch.tasks) {
        const userSelections = this.productionBatch.tasks.filter(
          (t) => t.source === 'USER_SELECTION'
        )
        if (userSelections.length > 0) {
          result = true
        }
      }
      return result
    },
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const resp = await this.$http.get(
        `/productions/batches/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields': productionFields,
          },
        }
      )
      this.productionBatch = resp.data
      this.loading = false
    },
    async unlink(taskId) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.delete(`/productions/tasks/${taskId}/unlink/`, {
        headers: {
          'X-Fields': 'id',
          'X-CSRF-TOKEN': respCsrf.data.csrf,
        },
      })
      this.init()
    },
    onDelete() {
      this.$router.push('/productions')
    },
    downloadChronofresh(countryCode) {
      this.$http({
        url: this.productionBatch.chronofresh_csv_url,
        method: 'GET',
        params: { country_code: countryCode },
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'text/csv',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `leonetmarcel_chronofresh-${this.productionBatch.public_reference}.csv`
        )
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style scoped>
.card-body {
  padding: 1rem !important;
}
</style>
